import { ChatBox } from "../ChatBox";
import { VideoJS } from "../VideoJs";
// import {VideoJS} from '../VideoJS';

function App() {
  return (
    <div className="App">
      <ChatBox />
      {/* <VideoJS url="https://hnylearning.com/awarenessHubBackend_UAT/static/videos/insurance.mp4" /> */}
    </div>
  );
}

export default App;
